import React from 'react'
import Layout from '../components/layout/Layout'
import trailClosedImg from '../assets/images/trailClosedDetour800x400.jpg'

const NotFoundPage = () => (
  <Layout>
    <div id="not-found" className="page-content">
      <h1>NOT FOUND</h1>
      <p>Oops, we don't recognize that URL. Please try another path.</p>
      <img src={trailClosedImg} alt="trail closed" width="800" height="400" />
    </div>
  </Layout>
)

export default NotFoundPage
