import React from 'react'
import { Link } from 'gatsby'

const Footer = () => (
  <footer id="footer">
    <div className="container">
      <div className="item">Veteran & Woman-Owned Small Business</div>
      <div className="item">&copy; Treeland Trails 2024</div>
      <div className="item">
        <a href="mailto: hello@treelandtrails.com">hello@treelandtrails.com</a>
      </div>
    </div>
    <Link to="/">
      <div className="logo">&nbsp;</div>
    </Link>
  </footer>
)

export default Footer
