import React from 'react'
import Footer from './Footer'
import Hamburger from 'hamburger-react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import LogoLanding from './LogoLanding'
import Menu from './Menu'

import '../../assets/scss/main.scss'

let defaultHelmut = {
  title: 'Treeland Trails',
  description: [
    'Treeland Trails is inspired by the regenerative agriculture movement and ',
    'wants to restore the Cottonwood River Watershed in Siskiyou County CA USA',
    'back to a beautifully vibrant and healthy ecosystem.',
  ].join('\n'),
  keywords: [
    'regenerative agriculture, ranching, forestry, keyline, mountain biking',
  ].join('\n'),
}

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMenuVisible: false,
      loading: 'is-loading',
      isHomePage: props.isHomePage,
      helmutTitle: props.helmutTitle || defaultHelmut.title,
      helmutPageDescription:
        props.helmutDescription || defaultHelmut.description,
      helmutPageKeywords: props.helmutKeywords || defaultHelmut.keywords,
      showHeader: true,
    }
    this.handleToggleMenu = this.handleToggleMenu.bind(this)
    this.setIsMenuVisible = this.setIsMenuVisible.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
  }

  handleScroll = () => {
    // hide header elements initially on home page because of blocking logo
    if (this.state.isHomePage) {
      this.setState({
        showHeader: window.pageYOffset < 150 ? false : true,
      })
    }
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ loading: '' })
    }, 100)

    this.handleScroll() // call the method now
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleToggleMenu = () => {
    this.setState({
      isMenuVisible: !this.state.isMenuVisible,
    })
  }

  setIsMenuVisible = (value) => {
    this.setState({
      isMenuVisible: value,
    })
  }

  closeMenu = () => {
    this.setIsMenuVisible(false)
  }

  render() {
    const { children } = this.props
    let logo
    let showHeader = true
    if (this.state.isHomePage) {
      logo = <LogoLanding />
      showHeader = this.state.showHeader
    }

    return (
      <div
        id={this.state.isHomePage ? 'home-page-layout' : 'page-layout'}
        className={`layout body ${this.state.loading} ${
          this.state.isMenuVisible ? 'is-menu-visible' : ''
        }`}
      >
        <Helmet
          title={this.state.helmutTitle}
          meta={[
            {
              name: 'description',
              content: this.state.helmutPageDescription,
            },
            { name: 'keywords', content: this.state.helmutPageKeywords },
          ]}
        ></Helmet>

        {showHeader && (
          <Hamburger
            size="20"
            toggled={this.state.isMenuVisible}
            toggle={this.setIsMenuVisible}
          />
        )}

        {logo}

        <header id="header" className="alt">
          {showHeader && (
            <Link to="/" className="logo-name">
              <span className="treeland">Treeland</span> <br />
              <span className="trails">Trails</span>
            </Link>
          )}
          <Link to="/" className="logo" />
        </header>

        <div className="page-wrapper">{children}</div>

        <Footer />
        <Menu closeMenu={this.closeMenu} />
      </div>
    )
  }
}

export default Layout
