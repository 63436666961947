import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const LogoLanding = () => {
  const [opacity, setOpacity] = React.useState(1)

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo/TreelandTrailsLogo800.png" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED)
        }
      }
    }
  `)
  const image = getImage(data.logo.childImageSharp)

  React.useEffect(() => {
    function onScroll() {
      const calcOpacity = (lowY, highY, currentY, fadeIn) => {
        let deltaY = highY - lowY
        if (currentY < lowY || deltaY <= 0) {
          return fadeIn ? 0 : 1
        } else if (currentY > highY) {
          return fadeIn ? 1 : 0
        } else {
          let percent = (currentY - lowY) / deltaY
          return fadeIn ? percent : 1 - percent
        }
      }
      setOpacity(
        calcOpacity(0, 1.25 * window.innerHeight, window.scrollY, false)
      )
    }

    onScroll() // call the method on load
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <div id="logolanding" style={{ opacity: `${opacity}` }}>
      <div id="bg">
        <GatsbyImage image={image} alt="Treeland Trails Logo" />
      </div>
    </div>
  )
}

export default LogoLanding
