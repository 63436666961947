import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = (props) => (
  <nav id="menu">
    <div className="inner">
      <ul className="links">
        <li>
          <Link onClick={props.closeMenu} to="/">
            Home
          </Link>
        </li>
        <li>
          <Link onClick={props.closeMenu} to="/regenerate">
            Regenerate
          </Link>
        </li>
        <li>
          <Link onClick={props.closeMenu} to="/diversify">
            Diversify
          </Link>
        </li>
        <li>
          <Link onClick={props.closeMenu} to="/" className="logo" />
        </li>
        <li>
          <Link onClick={props.closeMenu} to="/thoughts">
            Thoughts
          </Link>
        </li>
        <li>
          <Link onClick={props.closeMenu} to="/about">
            About
          </Link>
        </li>
      </ul>
    </div>
  </nav>
)

Menu.propTypes = {
  closeMenu: PropTypes.func,
}

export default Menu
